@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@layer components {
  .counter {
    @apply text-white text-3xl font-black text-center;
  }

  .counter span {
    @apply text-xs;
  }

  .soon .counter {
    @apply text-6xl lg:text-7xl p-5 active:ring-8 ring-darkPurple/50 hover:bg-darkPurple rounded-md duration-300 ease-in-out;
  }

  .soon .counter span {
    @apply text-xl;
  }

  .upcoming-card {
    @apply mb-10 flex items-center space-x-4 p-10 relative
      before:absolute before:top-0 before:h-full before:left-0 before:bg-[#8d061a]
      before:w-[60%] before:duration-500 before:ease-in-out before:z-[-1] 
      hover:before:bg-[#FF3636] hover:before:w-full hover:before:left-44 
      after:absolute after:top-0 after:left-0 after:h-full after:bg-[#222]
      after:w-[63%] after:duration-700 after:z-[-2] 
      hover:after:w-full hover:after:left-40;
  }
}

@font-face {
  font-family: "hacked";
  src: local("Hacked"), local("Hacked"),
    url(/public/fonts/hacked.ttf) format("truetype");
}

body {
  background-color: #0e0004;
}

.font-hacked {
  font-family: "hacked";
}

.gradient {
  background: linear-gradient(to top, #31081f, #0e0004);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #111;
}

::-webkit-scrollbar-thumb {
  background: #333;
}
